import {
  useContext, useEffect, useRef, useState, useMemo, useCallback, memo,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { debounce } from 'lodash';
import * as loglevel from 'loglevel';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField } from '@mui/material';
import SEPContext from '../../contexts/sep-context/SEPContext';
import env from '../../env/env';
import {
  ADDRESS, GOOGLE, GOOGLE_AUTOCOMPLETE, PARCEL,
  TOWN, ZIP, defaultSearchProviders,
} from './constants';
import {
  buildAddressString,
  getAddressLabel,
  getCoordinatesByZipCode,
  getGoogleCoordinates,
  getParcelLabel,
  getRequestOptions,
} from './utils';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

function AddressSearch({
  providers,
  onOptionSelect,
  onInputChange,
  onDebouncedQueryChange,
  onOptionsChange,
  autocompleteProps,
  inputProps,
  initialSearchQuery,
  /* eslint-disable */
  onCurrentPositionClick,
  grouped,
  hasCurrentLocation,
  /* eslint-disable */
}) {
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const { t } = useTranslation('search_bar');

  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [sessionToken, setSessionToken] = useState(uuidv4());
  const [searchOptions, setSearchOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const cancelToken = useRef(null);

  useEffect(() => {
    onDebouncedQueryChange(debouncedQuery);
    if (!debouncedQuery) return;

    (async () => {
      try {
        setIsLoading(true);

        if (cancelToken.current) {
          cancelToken.current.cancel('Operation canceled by the user.');
        }
        cancelToken.current = axios.CancelToken.source();

        const sortedKeys = Object.keys(providers).sort((a, b) => providers[a] - providers[b]);
        const requestConfigs = sortedKeys.map((key) => {
          const cToken = cancelToken.current.token;
          const requestOptions = getRequestOptions(debouncedQuery, jwt, cToken, sessionToken)[key];
          return {
            provider: key,
            requestOptions,
          };
        });

        const promises = requestConfigs.map((config) => {
          const { meta, ...requestOptions } = config.requestOptions;
          return axios(requestOptions)
            .then((response) => ({
              provider: config.provider,
              data: response.data,
              meta,
            }))
            .catch((error) => ({
              provider: config.provider,
              error,
              meta,
            }));
        });

        const responses = (await Promise.all(promises)).filter((r) => !r.error);

        const googleResponse = responses
          .find((response) => response.provider === GOOGLE);
        const googleAutocompleteResponse = responses
          .find((response) => response.provider === GOOGLE_AUTOCOMPLETE);
        const addressParcelResponse = responses
          .filter((response) => response.provider === ADDRESS || response.provider === PARCEL);
        const townZipResponse = responses
          .filter((response) => response.provider === TOWN || response.provider === ZIP);

        const ORDER_RESPONSE_MAP = {
          [ADDRESS]: [],
          [PARCEL]: [],
          [TOWN]: [],
          [ZIP]: [],
          [GOOGLE]: [],
          [GOOGLE_AUTOCOMPLETE]: [],
        };

        // extract place_ids from googleDecodeResponse
        const decodedPlaceIds = [];
        if (googleResponse && googleResponse.data && googleResponse.data.results) {
          googleResponse.data.results.forEach((result) => {
            const {
              name, place_id: placeId, address_components: ac, types,
            } = result;
            const formattedAddress = buildAddressString(ac);

            if (types.includes('country') || !formattedAddress) {
              return;
            }

            const { lat, lng } = getGoogleCoordinates(placeId, jwt)
              .then((response) => response.data.result.geometry.location)
              .catch((error) => {
                log.error('Error fetching place details:', error);
                return { lat: null, lng: null };
              });
            const optionLabel = !formattedAddress.toLocaleLowerCase()
              .includes(name.toLocaleLowerCase())
              ? `${name} (${formattedAddress})`
              : formattedAddress;
            decodedPlaceIds.push(placeId);
            ORDER_RESPONSE_MAP[GOOGLE].push({
              label: optionLabel,
              provider: GOOGLE,
              meta: googleResponse.meta,
              clientSideId: uuidv4(),
              addressId: placeId,
              coordinates: {
                latitude: lat,
                longitude: lng,
              },
              raw: result,
            });
          });
        }

        if (googleAutocompleteResponse
          && googleAutocompleteResponse.data
          && googleAutocompleteResponse.data.predictions) {
          const { predictions } = googleAutocompleteResponse.data;
          const filteredPredictions = predictions
            .filter((prediction) => !decodedPlaceIds.includes(prediction.place_id));
          filteredPredictions.slice(0, 3).forEach((prediction) => {
            const { description, place_id: placeId } = prediction;
            const optionLabel = description.includes(' District')
              ? description.replace(' District', '')
              : description;
            const { lat, lng } = getGoogleCoordinates(placeId, jwt)
              .then((response) => response.data.result.geometry.location)
              .catch((error) => {
                log.error('Error fetching place details:', error);
                return { lat: null, lng: null };
              });
            ORDER_RESPONSE_MAP[GOOGLE_AUTOCOMPLETE].push({
              label: optionLabel,
              provider: GOOGLE_AUTOCOMPLETE,
              meta: googleAutocompleteResponse.meta,
              clientSideId: uuidv4(),
              addressId: placeId,
              coordinates: {
                latitude: lat,
                longitude: lng,
              },
              raw: prediction,
            });
          });
        }

        addressParcelResponse.forEach((response) => {
          const { data } = response;
          data.rows.forEach((row) => {
            const { match, fields } = row;
            const optionLabel = response.provider === ADDRESS
              ? getAddressLabel(match, fields, t)
              : getParcelLabel(match, fields, t);
            ORDER_RESPONSE_MAP[response.provider].push({
              label: optionLabel,
              provider: response.provider,
              meta: response.meta,
              clientSideId: uuidv4(),
              addressId: fields.id,
              coordinates: {
                latitude: fields.lat_long ? parseFloat(fields.lat_long.split(';')[0]) : null,
                longitude: fields.lat_long ? parseFloat(fields.lat_long.split(';')[1]) : null,
              },
              raw: row,
            });
          });
        });

        townZipResponse.forEach((response) => {
          const { data } = response;
          data.forEach((row) => {
            const optionLabel = `${row.swissZipCode} ${row.town}`;
            const { lat, long } = getCoordinatesByZipCode(row.swissZipCode, jwt)
              .then((res) => res.data)
              .catch((error) => {
                log.error('Error fetching coordinates by zip code:', error);
                return { lat: null, long: null };
              });
            ORDER_RESPONSE_MAP[response.provider].push({
              label: optionLabel,
              provider: response.provider,
              meta: response.meta,
              clientSideId: uuidv4(),
              addressId: null,
              coordinates: {
                latitude: lat,
                longitude: long,
              },
              raw: row,
            });
          });
        });

        // max 3 entries for Google and autocomplete by mutating the array
        const googleEntries = ORDER_RESPONSE_MAP[GOOGLE].length > 0 ? 1 : 0;
        ORDER_RESPONSE_MAP[GOOGLE].splice(0, googleEntries);
        const googleAutocompleteEntries = googleEntries > 0 ? 2 : 3;
        ORDER_RESPONSE_MAP[GOOGLE_AUTOCOMPLETE].splice(0, googleAutocompleteEntries);

        const options = sortedKeys.reduce((acc, key) => acc.concat(ORDER_RESPONSE_MAP[key]), []);

        setSearchOptions(options);
        onOptionsChange(options);
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          log.info('Request canceled:', error.message);
        } else {
          log.error('Error fetching options:', error);
        }
        setIsLoading(false);
      }
    })();
  }, [
    jwt, t,
    debouncedQuery,
    sessionToken,
    onDebouncedQueryChange,
    providers,
    onOptionsChange,
  ]);

  const debouncedQuerySearch = useMemo(() => debounce((query) => {
    setDebouncedQuery(query);
  }, 400), []);

  const handleInputChange = useCallback((event, newInputValue, reason) => {
    const update = (newTextValue) => {
      setSearchQuery(newTextValue);
      onInputChange(newTextValue);
      setDebouncedQuery(newTextValue);
      setSearchOptions([]);
      setSessionToken(null);
    }
    if (reason === 'input') {
      if (!sessionToken) {
        setSessionToken(uuidv4()); // generate new session token when input starts
      }

      // when the user types in the input field
      setSearchQuery(newInputValue);
      onInputChange(newInputValue);

      if (newInputValue.length >= 1) {
        debouncedQuerySearch(newInputValue);
      } else {
        update('');
      }
    } else if (reason === 'clear') {
      // when the input is cleared (e.g., by clicking the clear button)
      update('');
    } else if (reason === 'reset') {
      // when the input value is reset (e.g., when the user selects an option)
      update(newInputValue);
    }
  }, [debouncedQuerySearch, onInputChange, sessionToken]);

  const handleOptionSelect = useCallback((event, value, reason) => {
    if (reason === 'selectOption') {
      onOptionSelect(value);
    }
  }, [onOptionSelect]);

  return (
    <Autocomplete
      {...autocompleteProps} // eslint-disable-line react/jsx-props-no-spreading
      value={searchQuery}
      options={searchOptions}
      loading={isLoading}
      onInputChange={handleInputChange}
      onChange={handleOptionSelect}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      renderInput={(params) => <TextField {...params} {...inputProps} />}
      renderOption={(optionProps, option) => (
        <div key={option.clientSideId} {...optionProps}>
          {option.label}
        </div>
      )}
      isOptionEqualToValue={(option, value) => {
        return option.label === value;
      }}
      // always display all options sent from the server without filtering
      filterOptions={() => searchOptions}
    />
  );
}

AddressSearch.defaultProps = {
  providers: defaultSearchProviders,
  autocompleteProps: {},
  inputProps: {},
  initialSearchQuery: '',
  grouped: true,
  hasCurrentLocation: true,
  onCurrentPositionClick: () => undefined,
  onOptionSelect: () => undefined,
  onInputChange: () => undefined,
  onDebouncedQueryChange: () => undefined,
  onOptionsChange: () => undefined,
};

export default memo(AddressSearch);
