export const endpoints = [
  {
    url: "/api/leads/{leadid:int}/contacts",
    name: "lead_contacts",
    params: ["leadid"],
  },
  {
    url: "/api/leads-by-leadid/{leadid:int}",
    name: "lead_details",
    params: ["leadid"],
  },
  {
    url: "/api/leads/{leadid:int}",
    name: "lead",
    params: ["leadid"],
  },
  {
    url: "/api/marketsense/egidfeatures-by-addressid/{addressid:int}",
    name: "e_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/parcelbuildfeatures-by-addressid/{addressid:int}",
    name: "pb_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/addresspoint-by-addressid/{addressid:int}",
    name: "a_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/heating-addresspoint-by-addressid/{addressid:int}",
    name: "ha_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/query-from-cloudant/aew_abwaermequellen/{addressid:int}",
    name: "custom_db",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/query-from-cloudant/aew_power_solution/{addressid:int}",
    name: "custom_db",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/parcelfeatures-by-addressid/{addressid:int}",
    name: "p_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/parcelfeatures-by-parcelid/{parcelid:int}",
    name: "p_by_p",
    params: ["parcelid"],
  },
  {
    url: "/api/marketsense/query-from-cloudant/{addressid:int}",
    name: "cloudant",
    params: ["addressid"],
  },
  {
    url: "/api/leads/{leadid:int}/notes",
    name: "notes",
    params: ["leadid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=0.75",
    name: "roof_statb_by_pb",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/query-from-customdb/aew_leads_kbdm/{leadid:int}",
    name: "customdb",
    params: ["leadid"],
  },
  {
    url: "/api/marketsense/fw-perimeter-by-addressid/{addressid:int}",
    name: "pe_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/ftth-perimeter-by-addressid/{addressid:int}",
    name: "peri_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/addresspoints-statistics-by-parcel-by-addressid/{addressid:int}",
    name: "a_statp_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/districtfeatures-by-addressid/{addressid:int}",
    name: "d_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[gt]10&klasse=[ge]1&belegungsgrad=0.75",
    name: "roof_statpb_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/zefix-statistics-by-egid-by-addressid/{addressid:int}",
    name: "zef_state_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&klasse=[ge]2&belegungsgrad=0.8",
    name: "roof_statp_by_pb",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcel-by-addressid/{addressid:int}?belegungsgrad=0.75",
    name: "roof_statp_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/leads/{leadid:int}/contacts?addresstype=null",
    name: "contact",
    params: ["leadid"],
  },
  {
    url: "/api/marketsense/districtfeatures-by-parcelid/{parcelid:int}",
    name: "d_by_a",
    params: ["parcelid"],
  },
  {
    url: "/api/serviceproxy-call-sequence/DecisionTree?addressid={addressid:int}&version=1.0.1&decisiontreeId=heating_replacement_default&decisiontreeVersion=1.0.0&jwt={token:string}",
    name: "decision_tree",
    params: ["addressid", "token"],
  },
  {
    url: "/api/serviceproxy-call-sequence/DecisionTree?addressid={addressid:int}&jwt={token:string}",
    name: "decision_tree",
    params: ["addressid", "token"],
  },
  {
    url: "/api/serviceproxy-call-sequence/DecisionTree?addressid={addressid:int}&version=1.0.1&jwt={token:string}",
    name: "decision_tree",
    params: ["addressid", "token"],
  },
  {
    url: "/api/serviceproxy-call-sequence/DecisionTree?addressid={addressid:int}&decisiontreeId=heating_replacement_default&decisiontreeVersion=1.0.0&jwt={token:string}",
    name: "decision_tree",
    params: ["addressid", "token"],
  },
  {
    url: "/api/serviceproxy-call-sequence/DecisionTree?addressid={addressid:int}&jwt={token:string}&decisiontreeId=heating_replacement_default&decisiontreeVersion=1.0.0",
    name: "decision_tree",
    params: ["addressid", "token"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcel-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=0.8&klasse=[ge]3",
    name: "roof_statp_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcel-by-addressid/{addressid:int}",
    name: "roof_statp_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcel-by-addressid/{addressid:int}?klasse=[ge]1&belegungsgrad=0.8",
    name: "roof_statp_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=0.8",
    name: "roof_statpb_by_a_080",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=0.7&klasse=[ge]3",
    name: "facade_statpb_by_a_ge3",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=1.0&klasse=[ge]2",
    name: "facade_statpb_by_a_ge2_kwh",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]0&belegungsgrad=1.0&klasse=[ge]1",
    name: "facade_statpb_by_a_no_filter",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]0&belegungsgrad=1.0&klasse=[ge]2",
    name: "facade_statpb_by_a_ge2_area",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]30&belegungsgrad=1&klasse=[ge]2",
    name: "facade_statpb_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=0.7&klasse=5",
    name: "facade_statpb_by_a_eq5",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=1.0&klasse=5",
    name: "facade_statpb_by_a_eq5_kwh",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]0&belegungsgrad=1.0&klasse=5",
    name: "facade_statpb_by_a_eq5_area",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=0.7&klasse=4",
    name: "facade_statpb_by_a_eq4",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=1.0&klasse=4",
    name: "facade_statpb_by_a_eq4_kwh",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]0&belegungsgrad=1.0&klasse=4",
    name: "facade_statpb_by_a_eq4_area",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=0.7&klasse=3",
    name: "facade_statpb_by_a_eq3",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=1.0&klasse=3",
    name: "facade_statpb_by_a_eq3_kwh",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]0&belegungsgrad=1.0&klasse=3",
    name: "facade_statpb_by_a_eq3_area",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=0.7&klasse=2",
    name: "facade_statpb_by_a_eq2",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=1.0&klasse=2",
    name: "facade_statpb_by_a_eq2_kwh",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]0&belegungsgrad=1.0&klasse=2",
    name: "facade_statpb_by_a_eq2_area",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=0.7&klasse=1",
    name: "facade_statpb_by_a_eq1",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=1.0&klasse=1",
    name: "facade_statpb_by_a_eq1_kwh",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]0&belegungsgrad=1.0&klasse=1",
    name: "facade_statpb_by_a_eq1_area",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=0.7&klasse=[ge]2",
    name: "facade_statpb_by_a_ge2",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&klasse=[ge]3&belegungsgrad=0.8",
    name: "roof_statpb_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/query-from-cloudant/leuk_data/{addressid:int}",
    name: "leuk_data",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/egidfeatures-statistics-by-parcel-by-addressid/{addressid:int}",
    name: "e_by_p",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]20&belegungsgrad=1&klasse=[ge]2",
    name: "roof_statpb_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=0.8&klasse=[ge]1",
    name: "roof_statpb_by_a_80",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/parcelbuildfeatures-statistics-by-parcel-by-addressid/{addressid:int}",
    name: "pb_statp_by_p",
    params: ["addressid"],
  },
  // {
  //   url: "/api/marketsense/buildingapplicationdetails-by-addressid/{addressid:int}",
  //   name: "documedia_details",
  //   params: ["addressid"],
  // },
  {
    url: "/api/marketsense/query-from-cloudant/netzbetrieber/{addressid:int}",
    name: "custom_db",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/zefix-statistics-by-parcel-by-addressid/{addressid:int}",
    name: "zef_statp_by_a",
    params: ["addressid"],
  },
  // {
  //   url: "/api/marketsense/buildingapplicationdetails-by-parcelid/{parcelid:int}?lastupdated=[ge]datetime(-0,-36,-0)&precision=[gt]6",
  //   name: "docu_by_p",
  //   params: ["parcelid"],
  // },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}",
    name: "roof_statpb_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-building-by-addressid/{addressid:int}",
    name: "roof_statb_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=0.8&klasse=[ge]3",
    name: "roof_statpb_by_a_ge3",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection[ge]10&klasse=[ge]3&belegungsgrad=0.8",
    name: "roof_statpb_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?belegungsgrad=1",
    name: "roof_statpb_by_a_100",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]10&belegungsgrad=1&klasse=[ge]1",
    name: "roof_statpb_by_a_100",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/query-from-customdb/pv_contracting/{addressid:int}",
    name: "custom_zefix",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/facade-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[gt]0&belegungsgrad=1&klasse=[ge]1",
    name: "facade_statpb_by_a_no_filter",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[gt]0&belegungsgrad=1&klasse=[ge]1",
    name: "roof_statpb_by_a_no_filter",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection=[ge]15&klasse=[ge]3&belegungsgrad=0.8",
    name: "roof_statpb_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcel-by-addressid/{addressid:int}?flaecheintersection[gt]0&klasse=[ge]1&belegungsgrad=1",
    name: "roof_statp_by_a_all",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/addresspoints-statistics-by-egid-by-addressid/{addressid:int}",
    name: "a_state_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcelbuilding-by-addressid/{addressid:int}?flaecheintersection[gt]0&klasse=[ge]1&belegungsgrad=1",
    name: "roof_statpb_by_a_all",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcel-by-addressid/{addressid:int}?klasse=[ge]3&belegungsgrad=0.75&flaecheintersection=[ge]10",
    name: "roof_statp_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcel-by-addressid/{addressid:int}?klasse=[ge]1&belegungsgrad=1&flaecheintersection=[ge]10",
    name: "roof_statp_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcel-by-addressid/{addressid:int}?klasse=[ge]1&belegungsgrad=0.75",
    name: "roof_statp_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcel-by-addressid/{addressid:int}?klasse=[ge]3&belegungsgrad=0.75",
    name: "roof_statp_by_a",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/query-from-cloudant/customer_data_bon_wet/{addressid:int}",
    name: "customer_data",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/query-from-cloudant/customer_data_hed/{addressid:int}",
    name: "customer_data",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/query-from-cloudant/customer_data_hut/{addressid:int}",
    name: "customer_data",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/query-from-cloudant/customer_data_rum/{addressid:int}",
    name: "customer_data",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/query-from-cloudant/repla_luterbach_stromverbrauch/{addressid:int}",
    name: "custom_data",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/query-from-cloudant/rmb_data_portfolio_1/{addressid:int}",
    name: "rmb",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/query-from-cloudant/rmb_data/{addressid:int}",
    name: "rmb_data",
    params: ["addressid"],
  },
  {
    url: "/api/marketsense/roof-statistics-by-parcel-by-addressid/{addressid:int}?flaecheintersection=[ge]10&klasse=[ge]1&belegungsgrad=0.75",
    name: "roof_statp_by_a",
    params: ["addressid"],
  },
];
