export const ADDRESS = 'address';
export const PARCEL = 'parcel';
export const TOWN = 'town';
export const ZIP = 'zip';
export const GOOGLE = 'google';
export const GOOGLE_AUTOCOMPLETE = 'googleAutocomplete';

export const defaultSearchProviders = {
  [ADDRESS]: 1,
  [PARCEL]: 2,
  [TOWN]: 3,
  [ZIP]: 4,
  [GOOGLE]: 5,
  [GOOGLE_AUTOCOMPLETE]: 6,
};
