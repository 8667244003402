import { useState } from 'react';
import { FormHelperText, FormGroup, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useTranslation } from 'react-i18next';

export default function UploadButton({
  editorContent, onClick, liveStatus, isDuplicatePanel,
}) {
  const { t } = useTranslation('admin');
  const [isLoading] = useState(false);
  const hasAConfigInEditor = editorContent !== null && editorContent !== '';
  return (
    <Box className="UploadButton" sx={{ maxWidth: '300px', width: '100%', mt: 1 }}>
      <FormGroup>
        {hasAConfigInEditor && (
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={liveStatus.isValid === false}
          color={isDuplicatePanel ? 'warning' : 'primary'}
          onClick={() => {
            if (isDuplicatePanel) {
              const confirm = window.confirm("You're about to overwrite an existing panel. Are you sure you want to continue?");

              if (!confirm) {
                return;
              }
            }

            onClick();
          }}
        >
          {t('upload-button', { ns: 'admin' })}
        </LoadingButton>
        )}
        {
          hasAConfigInEditor && (
          <FormHelperText error={!liveStatus.isValid}>
            <span>
              {t(`panel-config-validation-${liveStatus.isValid ? 'ok' : 'nok'}`, {
                ns: 'admin',
                replace: {
                  isValid: liveStatus.isValid.toString(),
                  panelType: liveStatus.panelType,
                },
              })}
            </span>
            {
              liveStatus.errors !== null && (
              <>
                <br />
                <span>
                  -
                  {' '}
                  {liveStatus.errors.toString()}
                </span>
              </>
              )
            }
          </FormHelperText>
          )
        }
      </FormGroup>
    </Box>
  );
}
