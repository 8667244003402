import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel, Accordion, AccordionDetails,
} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import {endpoints} from './endpoints';
import {useSearchParams} from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Fuse from 'fuse.js';
import {extractRequests, extractTextFromData, matchEndpoints} from "./utils";
import {fetchAPIEndpoints, fetchCollectionDetails, fetchCollections, getPanelSettings, getTenantLeads} from "./API";
import AddressSearch from "../../address_search/AddressSearch";
import {useTranslation} from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import Autocomplete from "@mui/material/Autocomplete";
import {docco} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import {Light as SyntaxHighlighter} from "react-syntax-highlighter";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";

const ResponseAccordion = memo(function ResponseAccordion({
  endpointName,
  responseObj,
  apiDocumentation,
  showDocumentation,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  // if responseObj.url includes jwt query parameter, replace it with empty string
  const url = responseObj.url.replace(/&jwt=[^&]*/, '');

  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          '& .MuiAccordionSummary-content': {
            flexDirection: 'column',
          },
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {endpointName}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontStyle: 'italic', color: 'gray' }}
        >
          Endpoint URL: {url}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {showDocumentation && apiDocumentation[endpointName] && apiDocumentation[endpointName].length > 0 && (
          <>
            <Typography sx={{ color: 'gray', mb: 2 }}>
              Postman Documentation: {apiDocumentation[endpointName].length} matching requests
            </Typography>
            {apiDocumentation[endpointName].map((doc, docIndex) => (
              <Accordion key={JSON.stringify(docIndex)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body2">
                    <strong>Method:</strong> {doc.method} <strong>Endpoint:</strong> {doc.url}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!doc.description.length && (
                    <Typography variant="body2">
                      No description provided for this request.
                    </Typography>
                  )}
                  {expanded && doc.description && (
                    <Box sx={{ paddingLeft: 2, overflowX: 'auto' }}>
                      <ReactMarkdown remarkPlugins={[remarkGfm]} skipHtml>{doc.description}</ReactMarkdown>
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
        {responseObj.error && (
          <Typography color="error">Error: {responseObj.error}</Typography>
        )}
        {!responseObj.error && responseObj.data && (
          <>
            <Typography variant="h6" sx={{ mt: 2 }}>
              SEP API Response data structure:
            </Typography>
            {expanded && (
              <SyntaxHighlighter language="json" style={docco} wrapLongLines>
                {JSON.stringify(responseObj.data, null, 2)}
              </SyntaxHighlighter>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
});


function ApiView() {
  const [queryParams] = useSearchParams();
  const { t } = useTranslation('lead_management');

  const [addressId, setAddressId] = useState('');
  const [leadId, setLeadId] = useState('');
  const [parcelId, setParcelId] = useState('');
  const [responses, setResponses] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [sepApiCacheDateTime, setSepApiCacheDateTime] = useState(null);
  const [leadsCacheDateTime, setLeadsCacheDateTime] = useState(null);
  const [postmanCacheDateTime, setPostmanCacheDateTime] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [apiDocumentation, setApiDocumentation] = useState({});
  const [showDocumentation, setShowDocumentation] = useState(
    JSON.parse(localStorage.getItem('showDocumentation')) || true
  );
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const fuseResponses = useMemo(() => {
    const entries = Object.entries(responses).map(([key, responseObj]) => ({
      key,
      url: responseObj.url,
      dataKeys: extractTextFromData(responseObj.data),
    }));

    return new Fuse(entries, {
      keys: ['key', 'url', 'dataKeys'],
      threshold: 0.3,
      ignoreLocation: true,
      includeScore: true,
    });
  }, [responses]);

  const fetchSepApiData = useCallback(async () => {
    setIsLoading(true);
    setResponses({});
    const newResponses = {};

    const providedParams = {
      addressid: addressId.trim() !== '',
      leadid: leadId.trim() !== '',
      parcelid: parcelId.trim() !== '',
      token: queryParams.get('jwt') !== '',
    };

    const eligibleEndpoints = endpoints.filter((endpoint) =>
      endpoint.params.every((param) => providedParams[param])
    );

    if (eligibleEndpoints.length === 0) {
      setResponses({
        error: 'No endpoints can be fetched with the provided parameters.',
      });
      setIsLoading(false);
      return;
    }

    const fetchPromises = eligibleEndpoints.map(async (endpoint) => {
      let url = endpoint.url;
      url = url.replace(/{addressid:int}/g, addressId);
      url = url.replace(/{leadid:int}/g, leadId);
      url = url.replace(/{parcelid:int}/g, parcelId);
      url = url.replace(/{token:string}/g, queryParams.get('jwt'));

      try {
        const response = await fetchAPIEndpoints(url, queryParams.get('jwt'));

        newResponses[endpoint.name] = {
          url: url,
          data: response,
        };
      } catch (error) {
        newResponses[endpoint.name] = {
          url: url,
          error: error.message,
        };
      }
    });

    await Promise.all(fetchPromises);

    setResponses(newResponses);
    setSepApiCacheDateTime(new Date());

    localStorage.setItem('apiParams', JSON.stringify({
      addressId,
      leadId,
      parcelId,
    }));
    localStorage.setItem('apiResponses', JSON.stringify(newResponses));
    localStorage.setItem('apiResponsesTimestamp', new Date().toISOString());

    setIsLoading(false);
  }, [
    addressId,
    leadId,
    parcelId,
    queryParams,
  ]);

  const fetchTenantLeads = useCallback(async () => {
    try {
      setIsLoading(true);
      const panels = await getPanelSettings(queryParams.get('jwt'));
      const geoimpactPanels = panels.filter((panel) => panel.mandant === 'geoimpact' && panel.panelType === "marketsense_v4");
      const allSettings = geoimpactPanels.map((panel) => JSON.parse(panel.value));
      const campaignIds = allSettings.map((settings) => settings.campaignId);
      const leadsRequests = campaignIds.map((campaignId) => getTenantLeads(queryParams.get('jwt'), campaignId));
      const leadsResponses = await Promise.all(leadsRequests);
      const getLabel = (leadObject) => [
        leadObject.street,
        leadObject.houseNumber,
        leadObject.swissZipCode,
        leadObject.town,
      ].filter(Boolean).join(' ');
      const newLeads = leadsResponses.flat()
        .map((leadObject) => ({
          label: getLabel(leadObject),
          id: leadObject.id,
          campaign: leadObject.campaign,
        }))
        .sort((a, b) => -b.campaign.localeCompare(a.campaign));
      setLeads(newLeads);
      setLeadsCacheDateTime(new Date());

      localStorage.setItem('leads', JSON.stringify(newLeads));
      localStorage.setItem('leadsTimestamp', new Date().toISOString());
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching leads:', error);
      setIsLoading(false);
    }
  }, [queryParams]);

  const fetchPostmanDocumentation = useCallback(async () => {
    try {
      setIsLoading(true);
      const collections = await fetchCollections();

      if (collections.length > 0) {
        const collectionDetailsPromises = collections.map((collection) =>
          fetchCollectionDetails(collection.uid)
        );

        const allCollectionDetails = await Promise.all(collectionDetailsPromises);

        let allRequests = [];
        allCollectionDetails.forEach((collectionDetails) => {
          if (collectionDetails) {
            const requests = extractRequests(collectionDetails);
            allRequests = allRequests.concat(requests);
          }
        });

        const matchedDocumentation = matchEndpoints(endpoints, allRequests);
        setApiDocumentation(matchedDocumentation);
        setPostmanCacheDateTime(new Date());

        localStorage.setItem('apiDocumentation', JSON.stringify(matchedDocumentation));
        localStorage.setItem('postmanCacheDateTime', new Date().toISOString());
      } else {
        console.error('No collections found.');
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching collection details:', error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const cachedDocumentation = JSON.parse(localStorage.getItem('apiDocumentation'));
        if (cachedDocumentation) {
          setApiDocumentation(cachedDocumentation);
          setPostmanCacheDateTime(new Date(localStorage.getItem('postmanCacheDateTime')));
        } else {
          await fetchPostmanDocumentation();
        }

      } catch (error) {
        console.error('Error fetching collection details:', error);
      }
    })();

    const cachedData = localStorage.getItem('apiResponses');
    const cachedTimestamp = localStorage.getItem('apiResponsesTimestamp');
    if (cachedData && cachedTimestamp) {
      setResponses(JSON.parse(cachedData));
      setSepApiCacheDateTime(new Date(cachedTimestamp));
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const cachedLeads = JSON.parse(localStorage.getItem('leads'));
        const cachedTimestamp = localStorage.getItem('leadsTimestamp');
        if (cachedLeads) {
          setLeads(cachedLeads);
          setLeadsCacheDateTime(new Date(cachedTimestamp));
        } else {
          await fetchTenantLeads();
        }
      } catch (error) {
        console.error('Error fetching leads:', error);
      }
    })();
  }, [queryParams]);

  useEffect(() => {
    if (selectedLead) {
      setLeadId(`${selectedLead.id}`);
    }
  }, [selectedLead]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleToggleDocumentation = () => {
    const newState = !showDocumentation;
    setShowDocumentation(newState);
    localStorage.setItem('showDocumentation', JSON.stringify(newState));
  };

  const handleSearch = useMemo(
    () =>
      debounce((event) => {
        setSearchQuery(event.target.value);
      }, 300),
    []
  );

  const handleRefreshSepApiDataSameParams = useCallback(async () => {
    const apiParams = JSON.parse(localStorage.getItem('apiParams'));
    if (apiParams) {
      setAddressId(apiParams.addressId);
      setLeadId(apiParams.leadId);
      setParcelId(apiParams.parcelId);
      await fetchSepApiData();
    }
  }, [fetchSepApiData]);

  const onOptionSelect = (option) => {
    setAddressId(`${option.addressId}`);
  };

  const onParcelSelect = (option) => {
    console.log('option:', option);
    setParcelId(`${option.addressId}`);
  };

  // const groupedResponses = groupResponsesByStructure(responses);
  // console.log('groupedResponses:', groupedResponses);

  const onLeadSelect = (event, value, reason) => {
    if (reason === 'selectOption') {
      console.log('option:', value);
      setSelectedLead(value);
    }
  };

  const filteredResponses = useMemo(() => {
    if (!searchQuery) return Object.entries(responses);

    return fuseResponses.search(searchQuery).map(({ item }) => [
      item.key,
      responses[item.key],
    ]);
  }, [searchQuery, responses, fuseResponses]);

  return (
    <Box sx={{ px: 2 }}>
      <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label="Search" />
        <Tab label="Data Fetcher" />
      </Tabs>

      {activeTab === 0 && (
        <Box>
          <TextField
            label="Search through API responses and Postman documentation"
            variant="outlined"
            defaultValue={searchQuery || ''}
            onChange={handleSearch}
            placeholder="Search API responses and documentation"
            fullWidth
          />
        </Box>
      )}

      {activeTab === 1 && (
        <Stack gap={1} sx={{ marginBottom: 2 }}>
          <AddressSearch
            providers={{ address: 1 }}
            initialSearchQuery={''}
            autocompleteProps={{
              freeSolo: true,
              selectOnFocus: true,
            }}
            inputProps={{
              label: t('reusable_autocomplete:address-search'),
            }}
            onOptionSelect={onOptionSelect}
          />
          <Autocomplete
            loading={isLoading}
            options={leads}
            getOptionKey={(option) => option.id + uuidv4()}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            groupBy={(option) => option.campaign}
            renderInput={(params) => <TextField {...params} label={selectedLead ? `Lead ID: ${selectedLead.id}` : 'Select the Lead'}/>}
            onChange={onLeadSelect}
          />
          <AddressSearch
            providers={{ parcel: 1 }}
            initialSearchQuery={''}
            autocompleteProps={{
              freeSolo: true,
              selectOnFocus: true,
            }}
            inputProps={{ label: 'Parcel Search' }}
            onOptionSelect={onParcelSelect}
          />
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <Stack>
              <Typography>
                Data is stored in the local storage to prevent unnecessary resource usage.
              </Typography>
              {sepApiCacheDateTime && (
                <Stack direction="row" alignItems="center">
                  <Typography variant="body2">
                    <strong>SEP API Cache Date-Time:</strong> {sepApiCacheDateTime.toLocaleString()}
                  </Typography>
                  <IconButton
                    title="Re-fetch the data by using the same parameters"
                    onClick={handleRefreshSepApiDataSameParams}
                    size="small"
                    sx={{ p: 0 }}
                    disabled={!localStorage.getItem('apiParams')}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Stack>
              )}
              {leadsCacheDateTime && (
                <Stack direction="row" alignItems="center">
                  <Typography variant="body2">
                    <strong>Leads Cache Date-Time:</strong> {leadsCacheDateTime.toLocaleString()}
                  </Typography>
                  <IconButton
                    title="Re-fetch the leads"
                    onClick={fetchTenantLeads}
                    size="small"
                    sx={{ p: 0 }}
                    disabled={isLoading}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Stack>
              )}
              {postmanCacheDateTime && (
                <Stack direction="row" alignItems="center">
                  <Typography variant="body2">
                    <strong>Postman Cache Date-Time:</strong> {postmanCacheDateTime.toLocaleString()}
                  </Typography>
                  <IconButton
                    title="Re-fetch the Postman documentation"
                    onClick={fetchPostmanDocumentation}
                    size="small"
                    sx={{ p: 0 }}
                    disabled={isLoading}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Stack>
              )}
            </Stack>
            <Stack direction="row" spacing={2} sx={{ marginLeft: 'auto' }}>
              <Box>
                <Button sx={{ mr: 1 }} variant="contained" onClick={fetchSepApiData} disabled={isLoading}>
                  {isLoading ? 'Loading...' : 'Fetch Data'}
                </Button>
                <Button variant="outlined" onClick={fetchSepApiData} disabled={isLoading}>
                  {isLoading ? 'Updating...' : 'Update API Response'}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      )}

      <FormControlLabel
        control={
          <Checkbox checked={showDocumentation} onChange={handleToggleDocumentation} />
        }
        label="Show Postman Documentation"
      />

      {isLoading && <Typography>Loading...</Typography>}
      {!isLoading && (
        <Box>
          {!responses.error && Object.keys(responses).length === 0 && (
            <Typography>No data fetched yet.</Typography>
          )}
          {responses.error && (
            <Typography color="error">{responses.error}</Typography>
          )}
          {!responses.error && filteredResponses
            .sort(([,a], [,b]) => -a.url.localeCompare(b.url))
            .map(([endpointName, responseObj], index) => (
              <ResponseAccordion
                key={JSON.stringify([endpointName, index])}
                endpointName={endpointName}
                responseObj={responseObj}
                apiDocumentation={apiDocumentation}
                showDocumentation={showDocumentation}
              />
            ))}
        </Box>
      )}
    </Box>
  );
}

export default ApiView;
