import { styled } from '@mui/material/styles';
import {
  Box, Drawer, CssBaseline, Divider, IconButton, Stack, Typography,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import CodeIcon from '@mui/icons-material/Code';
import Dimmer from "../../reusable/Dimmer";
import * as loglevel from "loglevel";
import env from "../../../env/env";
import SettingsView from "./SettingsView";
import ApiView from "./ApiView";
import MainView from "./MainView";
import {useMSCatalog} from "./MSCatalogProvider";
import {useState} from "react";

let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function MSCatalog({ open, setOpen }) {
  const { isLoading, error, bbApiToken } = useMSCatalog();

  const [view, setView] = useState(bbApiToken ? "main" : "settings");

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: '80vw',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: "80vw",
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Market Sense Details Catalog {view === "main" ? " - Main" : view === "settings" ? " - Settings" : " - API"}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            {view === "main" ? (
              <>
                <IconButton onClick={() => setView("settings")}>
                  <SettingsIcon />
                </IconButton>
                <IconButton onClick={() => setView("api")}>
                  <CodeIcon />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={() => setView("main")}>
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        </DrawerHeader>

        {isLoading && <Dimmer />}
        <Divider />

        {error && (
          <Box sx={{ padding: 2 }}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}

        {view === "settings" && (
          <SettingsView />
        )}
        {view === "api" && (
          <ApiView />
        )}
        {bbApiToken && !isLoading && !error && view === "main" && (
          <MainView />
        )}
      </Drawer>
    </Box>
  );
}
