import {
  useState, useContext, useEffect, useCallback,
} from 'react';
import './index.css';
import { createRoot } from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { HelmetProvider } from 'react-helmet-async';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import * as loglevel from 'loglevel';
import App from './components/app/App';

import { getI18nInstance } from './i18n/i18n';
import env from './env/env';
import SEPContext from './contexts/sep-context/SEPContext';
import LocationProvider from './contexts/location/location-context';
import MapProvider from './contexts/map/map-context';
import SelectionProvider from './contexts/selection/selection-context';
import PortalProvider from './contexts/portal/PortalProvider';
import DashboardProvider from './contexts/dashboard/DashboardProvider';
import FavoriteProvider from './contexts/favorites/FavoriteProvider';
import DeepStateProvider from './contexts/deepstate/DeepStateProvider';

import './util/Sentry';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

function AppRoot() {
  const [sepContextValue, setSepContextValue] = useState(useContext(SEPContext));
  const updateSEPContext = useCallback((newSepContext) => {
    setSepContextValue(newSepContext);
  }, []);
  const [i18n, setI18n] = useState(null);

  useEffect(() => {
    async function setupLocize() {
      const myI18n = await getI18nInstance();
      setI18n(myI18n);
    }
    setupLocize();

    const jwtFromParams = new URLSearchParams(window.location.search).get('jwt');
    if (jwtFromParams) {
      setSepContextValue((prev) => ({
        ...prev,
        SEPContext: {
          ...prev.SEPContext,
          user: {
            ...prev.SEPContext.user,
            jwt: jwtFromParams,
          },
        },
      }));
    }
  }, []);

  const MyApp = (
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n?.language || 'de-CH'}>
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <SEPContext.Provider value={{ ...sepContextValue, updateSEPContext }}>
          <DeepStateProvider>
            <PortalProvider>
              <LocationProvider>
                <MapProvider>
                  <SelectionProvider>
                    <FavoriteProvider>
                      <DashboardProvider>
                        <SEPContext.Consumer>
                          {(context) => (
                          // eslint-disable-next-line react/jsx-props-no-spreading
                            <App i18n={i18n} {...context} />
                          )}
                        </SEPContext.Consumer>
                      </DashboardProvider>
                    </FavoriteProvider>
                  </SelectionProvider>
                </MapProvider>
              </LocationProvider>
            </PortalProvider>
          </DeepStateProvider>
        </SEPContext.Provider>
      </LocalizationProvider>
    </HelmetProvider>
  );
  if (i18n) {
    return MyApp;
  }
  return null;
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<AppRoot />);
