import Dimmer from "../../reusable/Dimmer";
import {Stack, Typography, TextField, Autocomplete, ListItem, Collapse, Chip, Box} from "@mui/material";
import {Virtuoso} from "react-virtuoso";
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useMSCatalog} from "./MSCatalogProvider";
import {useCallback, useMemo, useState} from "react";
import Fuse from "fuse.js";
import {useTranslation} from "react-i18next";


SyntaxHighlighter.registerLanguage('javascript', javascript);

function MainView() {
  const { t } = useTranslation("dynamic_panel_marketsense_v4");
  const { isLoading, catalogData, error, tenantOptions, mscOptions } = useMSCatalog();

  const [nameFilter, setNameFilter] = useState('');
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedMsc, setSelectedMsc] = useState(null);
  const [expanded, setExpanded] = useState({});

  const handleExpandClick = (index) => {
    setExpanded(prevState => ({ ...prevState, [index]: !prevState[index] }));
  };

  if (isLoading) return <Dimmer />;

  if (error) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const fuse = useMemo(() => {
    return new Fuse(catalogData, {
      keys: ['label'],
      threshold: 0.3,
    });
  }, [catalogData]);

  const filteredOptions = useMemo(() => {
    let result = catalogData;
    if (nameFilter) {
      result = fuse.search(nameFilter).map(({ item }) => item);
    }
    if (selectedTenant) {
      result = result.filter(item =>
        item.expressions.some(exp =>
          exp.references.some(ref => selectedTenant in ref)
        )
      );
    }
    if (selectedMsc) {
      result = result.filter(item =>
        item.expressions.some(exp =>
          exp.references.some(ref =>
            Object.values(ref).some(mscList =>
              Array.isArray(mscList) && mscList.includes(selectedMsc)
            )
          )
        )
      );
    }
    return result;
  }, [catalogData, nameFilter, selectedTenant, selectedMsc, fuse]);

  const evalInContext = useCallback(function evalInContext(expression) {
    try {
      eval(expression);
      return this.res;
    } catch (e) {
      return e.message;
    }
  }, []);

  const evaluateLabel = (label) => {
    return evalInContext.call({ t }, label);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <TextField
        fullWidth
        size="small"
        label="Search Labels"
        variant="outlined"
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <Stack direction="row" gap={1} sx={{ marginBottom: 2 }}>
        <Autocomplete
          fullWidth
          size="small"
          options={tenantOptions}
          getOptionLabel={(option) => option}
          value={selectedTenant}
          onChange={(event, newValue) => setSelectedTenant(newValue)}
          renderInput={(params) => <TextField {...params} label="Select Tenant" variant="outlined" />}
          sx={{ marginBottom: 2 }}
        />
        <Autocomplete
          fullWidth
          size="small"
          options={mscOptions}
          getOptionLabel={(option) => option}
          value={selectedMsc}
          onChange={(event, newValue) => setSelectedMsc(newValue)}
          renderInput={(params) => <TextField {...params} label="Select MSC Panel" variant="outlined" />}
          sx={{ marginBottom: 2 }}
        />
      </Stack>

      <Virtuoso
        style={{ height: '70vh' }}
        totalCount={filteredOptions.length}
        itemContent={(index) => {
          const item = filteredOptions[index];
          const expandKey = `${index}-main`;
          const codeExpandKey = `${index}-code`;

          return (
            <ListItem alignItems="flex-start">
              <Stack sx={{ width: '100%' }}>
                <Box>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => handleExpandClick(expandKey)}
                  >
                    <Typography fontWeight={800} variant="h5">
                      {evaluateLabel(item.label)}
                    </Typography>
                    {expanded[expandKey] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Box>
                  <Collapse in={expanded[expandKey]} timeout="auto" unmountOnExit>
                    <Box
                      component="pre"
                      sx={{
                        backgroundColor: 'rgb(248, 248, 255)',
                        pl: 1,
                        borderRadius: 1,
                        overflowX: 'auto',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                      }}
                    >
                      <SyntaxHighlighter language="javascript" style={docco} wrapLongLines>
                        {item.label}
                      </SyntaxHighlighter>
                    </Box>
                  </Collapse>
                </Box>
                {item.expressions.map((exp, expIndex) => {
                  const expandKey = `${codeExpandKey}-${expIndex}`;
                  return (
                    <Box key={expIndex} sx={{ marginBottom: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleExpandClick(expandKey)}>
                        <Typography variant="body2" color="textSecondary">
                          References {exp.references.reduce((acc, ref) => acc + Object.values(ref).flat().length, 0)}
                        </Typography>
                        {expanded[expandKey] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Box>
                      <Collapse in={expanded[expandKey]} timeout="auto" unmountOnExit>
                        {exp.references.map((ref, refIndex) => (
                          <Box key={refIndex} sx={{ paddingLeft: 2 }}>
                            {Object.entries(ref).map(([tenant, mscList]) => (
                              <Box key={tenant} sx={{ marginBottom: 1 }}>
                                <Typography variant="body2" color="textPrimary" fontWeight="bold">
                                  {tenant}
                                </Typography>
                                <Stack direction="row" gap={0.5} flexWrap="wrap">
                                  {mscList.map((msc) => (
                                    <Chip
                                      key={msc}
                                      size="small"
                                      variant="outlined"
                                      label={msc}
                                      sx={{
                                        marginRight: 1,
                                        backgroundColor: msc.includes(' ') ? 'lightgreen' : 'lightcoral',
                                        color: msc.includes(' ') ? 'black' : 'white',
                                      }}
                                    />
                                  ))}
                                </Stack>
                              </Box>
                            ))}
                          </Box>
                        ))}
                      </Collapse>

                      <Stack gap={1} sx={{ marginTop: 1 }}>
                        <Typography>Expression {expIndex + 1}:</Typography>
                        <Box
                          sx={{
                            backgroundColor: 'rgb(248, 248, 255)',
                            pl: 1,
                            borderRadius: 1,
                            overflowX: 'auto',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                          }}
                        >
                          <SyntaxHighlighter language="javascript" style={docco} wrapLongLines>
                            {exp.expression}
                          </SyntaxHighlighter>
                        </Box>
                      </Stack>
                    </Box>
                  );
                })}
              </Stack>
            </ListItem>
          );
        }}
      />
    </Box>
  );
}

export default MainView;
