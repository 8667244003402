export function extractRequests(collection) {
  const items = collection.item;
  const requests = [];

  function traverseItems(items) {
    items.forEach((item) => {
      if (item.request) {
        const request = item.request;
        let url = '';

        if (request.url) {
          if (typeof request.url === 'string') {
            url = request.url;
          } else if (typeof request.url === 'object') {
            url = request.url.raw || '';
          }
        }

        const method = request.method;

        const requestEntry = {
          name: item.name,
          method: method,
          url: url,
          urlOriginal: request.url?.raw || request.url,
          description: request.description || item.description || '',
        };

        requests.push(requestEntry);
      }

      if (item.item) {
        traverseItems(item.item);
      }
    });
  }

  traverseItems(items);

  return requests;
}

export function normalizeUrlForComparison(url) {
  if (!url || typeof url !== 'string') {
    return '';
  }

  // Remove protocol and domain
  url = url.replace(/https?:\/\/[^/]+/, '');

  // Remove any query parameters (everything after '?')
  url = url.split('?')[0];

  // Remove trailing slashes
  url = url.replace(/\/$/, '');

  // Replace path parameters like {param} and {{param}} with ''
  url = url.replace(/{[^}]+}/g, '');
  url = url.replace(/{{[^}]+}}/g, '');

  // Remove any numeric segments at the end (assumed to be dynamic IDs)
  url = url.replace(/\/\d+$/, '');

  // Remove trailing slashes
  url = url.replace(/\/$/, '');

  return url;
}

export function matchEndpoints(apiEndpoints, postmanRequests) {
  const matchedDocumentation = {};

  apiEndpoints.forEach((endpoint) => {
    const normalizedApiUrl = normalizeUrlForComparison(endpoint.url);

    // Find all matching requests that have the same normalized URL
    const matchedRequests = postmanRequests.filter((request) => {
      const normalizedRequestUrl = normalizeUrlForComparison(request.url);

      if (!normalizedApiUrl || !normalizedRequestUrl) {
        return false;
      }

      return normalizedApiUrl === normalizedRequestUrl;
    });

    if (!matchedDocumentation[endpoint.name]) {
      matchedDocumentation[endpoint.name] = [];
    }

    matchedRequests.forEach((matchedRequest) => {
      matchedDocumentation[endpoint.name].push({
        name: endpoint.name,
        url: endpoint.url,
        params: endpoint.params,
        method: matchedRequest.method,
        description: matchedRequest.description,
      });
    });
  });

  return matchedDocumentation;
}

// Utility function to extract keys from response objects
export function extractKeys(data) {
  if (!Array.isArray(data) || data.length === 0) return [];
  return Object.keys(data[0]);
}

// Utility function to compare keys of two responses
export function areKeysEqual(keys1, keys2) {
  if (keys1.length !== keys2.length) return false;
  return keys1.every((key) => keys2.includes(key));
}

// Group responses with the same keys
export function groupResponsesByStructure(responses) {
  const groupedResponses = [];

  Object.entries(responses).forEach(([endpointName, responseObj]) => {
    const keys = extractKeys(responseObj.data);

    // Check if the response structure matches any existing group
    let groupFound = false;
    for (const group of groupedResponses) {
      if (areKeysEqual(group.keys, keys)) {
        group.endpoints.push({
          endpointName,
          url: responseObj.url,
        });
        groupFound = true;
        break;
      }
    }

    // If no matching group was found, create a new group
    if (!groupFound) {
      groupedResponses.push({
        keys: keys,
        endpoints: [{
          endpointName,
          url: responseObj.url,
        }],
        data: responseObj.data,
      });
    }
  });

  return groupedResponses;
}

export function extractTextFromData(data) {
  if (!Array.isArray(data)) {
    return [];
  }

  const keysSet = new Set();

  data.forEach((item) => {
    if (item && typeof item === 'object') {
      Object.keys(item).forEach((key) => keysSet.add(key));
    }
  });

  return Array.from(keysSet);
}
