import React from 'react';

const getJWTFromQueryString = () => {
  const params = new URLSearchParams(window.location.search);
  const jwt = params.get('jwt');
  return jwt || '';
};

export const defaultContext = {
  panelSettings: [],
  isLoading: false,
  jwt: getJWTFromQueryString(),
  tenant: null,
  user: '',
  panelType: null,
  category: null,
  dispatch: () => {},
  getPanelSettings: async () => {},
  updatePanelSettings: async () => {},
  postPanelSettingsAsAdmin: async () => {},
  deletePanelSettingsAsAdminById: async () => {},
  getSettingsTree: () => {},
  setState: () => {},
};

export const PanelConfigContext = React.createContext(defaultContext);

export function PanelConfigContextProvider({ children, value }) {
  return (
    <PanelConfigContext.Provider value={value || defaultContext}>
      {children}
    </PanelConfigContext.Provider>
  );
}
