import {Checkbox, TextField, FormControlLabel, Stack, Box} from "@mui/material";
import {useCallback, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useMSCatalog} from "./MSCatalogProvider";
import Typography from "@mui/material/Typography";

function SettingsView() {
  const { bbApiToken, setBBApiToken, postmanApiKey, setPostmanApiKey, } = useMSCatalog();
  const [queryParams, setSearchParams] = useSearchParams();
  const [saveToken, setSaveToken] = useState(!!bbApiToken);
  const [savePostmanApiKey, setSavePostmanApiKey] = useState(!!postmanApiKey);

  const handleSaveTokenChange = (event) => {
    setSaveToken(event.target.checked);
    if (!event.target.checked) {
      localStorage.removeItem("bbApiToken");
    } else {
      localStorage.setItem("bbApiToken", bbApiToken);
    }
  };

  const handleTokenInputChange = useCallback((event) => {
    const token = event.target.value;
    setBBApiToken(token);

    if (saveToken) {
      localStorage.setItem("bbApiToken", token);
    } else {
      localStorage.removeItem("bbApiToken");
    }

    const currentParams = Object.fromEntries(queryParams.entries());
    if (!token) {
      delete currentParams.bbApiToken;
    } else {
      currentParams.bbApiToken = token;
    }
    setSearchParams(currentParams);
  }, [saveToken, queryParams, setSearchParams]);

  const handleSavePostmanApiKeyChange = (event) => {
    setSavePostmanApiKey(event.target.checked);
    if (!event.target.checked) {
      localStorage.removeItem('postmanApiKey');
    } else {
      localStorage.setItem('postmanApiKey', postmanApiKey);
    }
  };

  const handlePostmanApiKeyInputChange = useCallback((event) => {
    const apiKey = event.target.value;
    setPostmanApiKey(apiKey);

    if (savePostmanApiKey) {
      localStorage.setItem('postmanApiKey', apiKey);
    } else {
      localStorage.removeItem('postmanApiKey');
    }
  }, [savePostmanApiKey, queryParams, setSearchParams]);

  return (
    <Stack gap={2} sx={{ padding: 2 }}>
      <Typography>
        Please provide the market-senses repository API token. You can find the token in Keeper under the following link: <a target="_blank" href="https://keepersecurity.eu/vault/#detail/L-qR488Dgp5_tOGFcskY7Q">Atlassian Admin</a>
      </Typography>
      <Box>
        <TextField
          fullWidth
          multiline
          label="market-senses Repository API Token"
          variant="outlined"
          value={bbApiToken}
          onChange={handleTokenInputChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={saveToken}
              onChange={handleSaveTokenChange}
            />
          }
          label="Save the token to local storage"
        />
      </Box>

      <Typography>
        Please provide the postman API key. You can create new API key in Postman under the following link: <a target="_blank" href="https://geoimpact.postman.co/settings/me/api-keys">Postman Profile Settings</a>
      </Typography>
      <Box>
        <TextField
          fullWidth
          multiline
          label="Postman API Key"
          variant="outlined"
          value={postmanApiKey}
          onChange={handlePostmanApiKeyInputChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={savePostmanApiKey}
              onChange={handleSavePostmanApiKeyChange}
            />
          }
          label="Save Postman API key to local storage"
        />
      </Box>
    </Stack>
  );
}

export default SettingsView;
