import axios from 'axios';
import env from '../../env/env';

export const getRequestOptions = (query, jwt, cancelToken, sessionToken = null) => {
  const meta = { group: 'SEP', query };
  const postRequestDefaults = {
    method: 'POST',
    cancelToken,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${jwt}`,
    },
    data: (() => {
      const formData = new FormData();
      formData.append('searchtext', query);
      return formData;
    })(),
  };
  const getRequestDefaults = {
    cancelToken,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  };
  const encodeQuery = encodeURIComponent(query);

  return {
    address: {
      meta,
      url: `${env.API_GATEWAY_BASE}/api/marketsense/searchaddress-advanced`,
      ...postRequestDefaults,
    },
    parcel: {
      meta,
      url: `${env.API_GATEWAY_BASE}/api/marketsense/searchparcel`,
      ...postRequestDefaults,
    },
    regionTown: {
      meta,
      url: `${env.API_GATEWAY_BASE}/api/addresspoints-find-town-swisszipcode?town=${encodeQuery}`,
      ...getRequestDefaults,
    },
    regionZip: {
      meta,
      url: `${env.API_GATEWAY_BASE}/api/addresspoints-find-swisszipcode-town?zipcode=${encodeQuery}`,
      ...getRequestDefaults,
    },
    google: {
      meta: { group: 'GOOGLE', query },
      url: `${env.API_GATEWAY_BASE}/google-geocode/json?address=${encodeQuery}&components=country:CH&sessiontoken=${sessionToken}`,
      ...getRequestDefaults,
    },
    googleAutocomplete: {
      meta: { group: 'GOOGLE', query },
      url: `${env.API_GATEWAY_BASE}/google-places-autocomplete/json?input=${encodeQuery}&types=address&components=country:ch&sessiontoken=${sessionToken}`,
      ...getRequestDefaults,
    },
  };
};

export const getGoogleCoordinates = async (placeId, jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/google-places/details/json?place_id=${encodeURIComponent(placeId)}`,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});

export const getCoordinatesByZipCode = async (zip, jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/api/addresspoints-find-plz-coordinates/${zip}`,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});

export function buildAddressString(addressComponents) {
  // Return null if there are insufficient address components.
  if (addressComponents.length <= 3) {
    return null;
  }

  // Define the structure of the address using an object.
  const address = {
    route: '',
    street_number: '',
    postal_code: '',
    locality: '',
    administrative_area_level_1: '',
    administrative_area_level_2: '',
  };

  // Iterate through the address components and map them to the according fields.
  addressComponents.forEach((component) => {
    const type = component.types.find((t) => Object.keys(address).includes(t));
    if (type) {
      address[type] = component.long_name;
    }
  });

  if (address.administrative_area_level_2 !== '' && address.administrative_area_level_2.includes(' District')) {
    address.administrative_area_level_2 = address.administrative_area_level_2.replace(' District', '');
  }

  // Create an array to store the different parts of the formatted address.
  const formattedAddressParts = [
    // Add the route and street number, if available.
    `${address.route}${address.street_number ? ` ${address.street_number}` : ''}`,
    // Add the postal code and locality, if available.
    address.postal_code ? `${address.postal_code} ${address.locality}`.trim() : address.locality,
    // Add the administrative area only if it's not equal to the locality.
    address.administrative_area_level_2
    && address.administrative_area_level_2 !== address.locality
      ? address.administrative_area_level_2
      : '',
    // Add the administrative area only if it's not equal to the locality.
    address.administrative_area_level_1
    && address.locality !== address.administrative_area_level_1
    && address.administrative_area_level_1 !== address.administrative_area_level_2
      ? address.administrative_area_level_1
      : '',
  ];

  // Filter out any empty parts, and join the remaining parts with a comma and a space.
  return formattedAddressParts.filter((part) => part).join(', ');
}

export const getAddressLabel = (match, fields, t) => {
  const attribute = ['egid', 'egrid', 'egaid', 'parz_nr'].find((value) => match.includes(value));
  return attribute
    ? `${fields.address} (${t(`res-address-${attribute}`)} ${fields[attribute]})`
    : fields.address;
};

export const getParcelLabel = (match, fields, t) => {
  let label = `${t('res-parcel')} ${fields.parz_nr}, ${fields.districts.split('|').join('/')}`;
  if (match.includes('egrid') && !['town', 'districts'].find((value) => match.includes(value))) {
    label = `${label} (${t('res-parcel-egrid')} ${fields.egrid})`;
  }
  return label;
};
