import {createContext, useContext} from 'react';

const MSCatalogContext = createContext(null);

import { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from "react-router-dom";
import * as loglevel from "loglevel";
import env from "../../../env/env";
import {fetchCatalogData} from "./API";

let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export function MSCatalogProvider({ children }) {
  const [queryParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [catalogData, setCatalogData] = useState([]);
  const [bbApiToken, setBBApiToken] = useState(localStorage.getItem("bbApiToken") || queryParams.get("bbApiToken") || "");
  const [postmanApiKey, setPostmanApiKey] = useState('');
  const [tenantOptions, setTenantOptions] = useState([]);
  const [mscOptions, setMscOptions] = useState([]);

  const [error, setError] = useState(null);
  const [saveToken, setSaveToken] = useState(!!bbApiToken);

  useEffect(() => {
    if (!bbApiToken) return;
    (async () => {
      try {
        const response = await fetchCatalogData(bbApiToken);
        setCatalogData(response);

        const tenants = new Set();
        const mscs = new Set();

        response.forEach(item => {
          item.expressions.forEach(exp => {
            exp.references.forEach(ref => {
              Object.keys(ref).forEach(tenant => {
                tenants.add(tenant);
                ref[tenant].forEach(msc => mscs.add(msc));
              });
            });
          });
        });

        setTenantOptions([...tenants]);
        setMscOptions([...mscs]);
        setIsLoading(false);
      } catch (e) {
        log.debug(e);
        setIsLoading(false);
        setError('Failed to fetch catalog data. Please check your API token.');
      }
    })();
  }, [bbApiToken]);

  useEffect(() => {
    const bbToken = localStorage.getItem("bbApiToken") || queryParams.get("bbApiToken");
    if (!bbToken) {
      setError("No Bitbucket API token provided. Please provide a token in the URL query parameters with the key 'bbApiToken'.");
      setIsLoading(false);
      return;
    }
    const savedPostmanApiKey = localStorage.getItem('postmanApiKey');
    if (savedPostmanApiKey) {
      setPostmanApiKey(savedPostmanApiKey);
    }

    setBBApiToken(bbToken);
  }, [queryParams]);

  const api = useMemo(() => ({
    isLoading,
    error,
    catalogData,
    bbApiToken,
    tenantOptions,
    mscOptions,
    saveToken,
    setSaveToken,
    setSearchParams,
    postmanApiKey,
    setPostmanApiKey,
    setBBApiToken,
  }), [
    isLoading,
    error,
    catalogData,
    bbApiToken,
    tenantOptions,
    mscOptions,
    saveToken,
    postmanApiKey,
  ]);

  return (
    <MSCatalogContext.Provider value={api}>
      {children}
    </MSCatalogContext.Provider>
  );
}


export function useMSCatalog() {
  const context = useContext(MSCatalogContext);
  if (!context) {
    throw new Error('useMSCatalog must be used within a MSCatalogProvider');
  }
  return context;
}
